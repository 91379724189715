@import "/src/_styles/mixin";

$red: #e22028;
$border-color: #4a4848;

.input_radio_base {
  position: relative;

  .error_select {
    span {
      color: $red;
      font-size: 18px;
      display: flex;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .error_text {
    margin-bottom: 9px;
  }

  .error_text_absolute {
    position: absolute;
    bottom: calc(100% + 2px);
  }

  .input_select {
    cursor: pointer;
  }

  .input_select_main_wrapper {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 0px 22px;
    border: 1px solid $border-color;
    border-radius: 10px;
    margin-bottom: 22px;

    @media screen and (max-width: 768px) {
      height: 44px;

      svg {
        width: 18px;
        height: 22px;
      }
    }

    .input_select_main {
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;
      padding: 1px 0px;
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;

      .icon_label {
        width: 31px;
        margin-right: 26px;
        border: 0px solid transparent;
        border-bottom-width: 1px;

        @include screen_mobile {
          margin-right: 0 !important;
        }
      }

      .input_text {
        height: 100%;
        border: none;
        font-size: 18px;
        // line-height: 21px;
        flex: 1;
        background-color: transparent;
        opacity: 1;

        &:focus {
          outline: none;
        }

        @include screen_mobile {
          font-size: 0.9rem !important;
          margin-top: 1%;
        }
      }
    }
  }

  .drop_down {
    position: absolute;
    display: none;
    top: 65px;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #bbbbbb;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      margin: 0.5rem 0;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #8b8b8b;
    }

    .option_item {
      padding: 12px 30px;

      &:hover {
        background-color: whitesmoke;
        cursor: pointer;
      }

      span {
        font-size: 18px;
        font-family: "Myriadpro-Regular";
        color: #707070;

        @include screen_mobile {
          font-size: 0.9rem;
        }
      }
    }

    &.active {
      display: block;
    }
  }

  .drop_down_news {
    top: clamp(2.4rem, 3vw, 2.8rem);
    border: 1px solid var(--text-color);
    border-radius: 0.5rem;
    width: 54%;
    right: -20%;
    text-align: center;
    min-width: 13rem;

    @include screen_mobile {
      right: 3%;
      min-width: 12.5rem;
    }

    .option_item {
      padding: 12px 20px;

      span {
        font-size: 18px;
        font-family: "AvertaStdCY" !important;
        color: #707070;

        @include screen_mobile {
          font-size: 0.875rem;
        }
      }
    }
  }

  &.focus {
    box-shadow: 1px 3px 3px 0px rgba(23, 23, 23, 0.16);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .input_select_main_wrapper {
      border-color: white;
      border-radius: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: white;
    }

    .drop_down {
      box-shadow: 1px 3px 3px 0px rgba(23, 23, 23, 0.16);
    }

    .input_select_main {
      border: 0px solid #a1afbe;
      border-bottom-width: 1px;
    }
  }

  &.warning_select {
    .input_select_main_wrapper {
      border-color: $red !important;
      border-width: 2px !important;
      border-style: solid !important;
    }
  }

  .rotate_180 {
    transform: rotate(180deg);
  }

  .cursor_pointer {
    cursor: pointer;
  }

  input {
    font-family: "Myriadpro-Regular";

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: "Myriadpro-Regular";
      color: #8e9298;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: "Myriadpro-Regular";
      color: #8e9298;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-family: "Myriadpro-Regular";
      color: #8e9298;
    }
  }
}

.input_radio_base_news {
  &.focus {
    border-radius: 10px;

    .input_select_main_wrapper {
      border-radius: 10px;
    }

    .input_select_main {
      border: none;
    }
  }

  .input_select_main_wrapper_news {
    padding: 0 1.2rem !important;
  }

  .input_text {
    width: 75% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include screen_mobile {
      width: 100%;
      max-width: 100% !important;
    }
  }
}