.register_step_success {
  .title_register {
    margin-bottom: 36px;
  }

  .success_icon {
    text-align: center;
    margin-bottom: 44px;
  }

  .success_message {
    text-align: center;

    p {
      font-size: 18px;
    }
  }

  .back_login {
    text-align: center;
    margin-top: 44px;
    display: flex;
    align-items: center;
    gap: 32px;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 16px;
    }

    button {
      width: 210px;
      height: 50px;
      line-height: unset;
      border-radius: 25px;
      font-size: 18px;
    }
  }

  .btn-back {
    width: 100px;
    background-image: none;
    background:
      linear-gradient(#F3FFFF, #F3FFFF) padding-box,
      linear-gradient(to right, #00b9b7, #00e1a0) border-box;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 10px;

    span {
      font-size: 18px;
      font-weight: 600;
      background: -webkit-linear-gradient(#00b9b7, #00e1a0);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: 100%;
    }

    :hover {
      opacity: 0.6;
    }
  }
}